<template>
  <div class="style-deep-cover">
    <div class="page-index flex">
      <div class="main-wrap flex1 flex flex-dc">
        <!-- content-wrap start -->
        <div class="content-wrap flex1 flex flex-dc flex-ac" v-loading="loading">
          <div class="title flex flex-ac flex-jc">Spread CRM Platform</div>
          <div class="flex1 form flex flex-dc flex-jc">
            <div class="icon-wrap flex flex-ac flex-jc">
              <i class="el-icon-user"></i>
            </div>

            <div class="form-wrap">
              <div class="form-group">
                <el-input 
                  ref="username"
                  tabindex="1" 
                  v-model="username" 
                  placeholder="Username" 
                  clearable
                  autocomplete="on"
                  @blur="getSalt()"
                ></el-input>
              </div>
              <div class="form-group">
                <el-input 
                  ref="password" 
                  tabindex="2" 
                  placeholder="Password" 
                  v-model="password" 
                  show-password 
                  clearable></el-input>
              </div>
              <div class="form-group vercode-form-group flex flex-ac" v-if="userSalt.base64">
                <el-input
                  ref="verCode"
                  v-model="verCode"
                  placeholder="VerCode"
                  name="verCode"
                  type="text"
                  tabindex="3"
                  @keyup.enter.native="login"
                />
                <div class="pic-wrap flex flex-ac flex-jc">
                  <img :src="userSalt.base64" @click="getSalt()">
                </div>
              </div>
              <div class="form-group">
                <el-button type="success" style="width:100%" @click="login()">Log In</el-button>
              </div>
            </div>

          </div>
        </div>
        <!-- content-wrap end -->
      </div>
    </div>
  </div>
  
</template>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.style-deep-cover /deep/ {
  height:100%;
  overflow:hidden;

  .page-index {
    height:100%;
    overflow:hidden;
    position:relative;
    color:#555;

    .main-wrap {
      overflow:hidden;
      background-color:#f9f9f9;

      .header-wrap {
        height:calc(4vw + 40px);
        margin: 20px 0;
        padding: 0 1vw;

        .img-wrap {
          height:100%;
          margin-right:30px;
        }
      }

      .content-wrap {
        background-color:$--color-primary;

        .title {
          color:#fff;
          font-size:calc(0.8vw + 8px);
          font-weight:bold;
          padding-top:3vw;
        }

        .icon-wrap {
          color:#fff;
          font-size:calc(5vw + 50px);
          margin-bottom:2vw;
        }

        .form-wrap {
          width:calc(15vw + 150px);

          .form-group {
            margin-bottom:1.5vw;
            position:relative;

            &.vercode-form-group .el-input__inner {
              padding-right:150px !important;
            }
          }

          .el-input__inner,
          button {
            height:calc(2.2vw + 22px);
            max-height:70px;
            font-size:calc(0.8vw + 7px);
          }

          .pic-wrap {
            position:absolute;
            top:2px;
            bottom:2px;
            right:5px;
            width:130px;
            height:calc(100% - 4px);
            overflow: hidden;

            img {
              width:100%;
            }
          }
        }
      }

    }
    
  }
}
</style>

<script>
import sha256 from 'js-sha256'
import * as userApi from '@/apis/user'

export default {
  data(){
    return {
      // loading flag
      loading: false,
      username: '',
      password: '',
      verCode: '',
      userSalt: {}
    }
  },
  created() {
    
  },
  mounted() {
    if (this.username === '') {
      this.$refs.username.focus()
    } else if (this.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {

    getSalt() {
      if(!this.username) return;

      this.verCode = '';
      this.loading = true;
      if(this.username){
        userApi.getUserSalt(this.username).then(res => {
          this.loading = false;
          this.userSalt = res;
        }).catch(() => {
          this.loading = false;
        });
      }
    },

    login() {

      this.loading = true;

      const timestamp = new Date().getTime();
      const username = this.username.trim();
      const data = {
        username  : username,
        password  : sha256(sha256(sha256(this.password).toUpperCase() + this.userSalt.salt).toUpperCase() + timestamp).toUpperCase(),
        verCode   : this.verCode,
        verKey    : this.userSalt.key,
        timestamp : timestamp
      }

      userApi.login(username, data).then(res => {
        this.loading = false;
        localStorage.setItem('SPREAD-CRM-USERINFO', JSON.stringify(res));
        localStorage.setItem('SPREAD-CRM-USERNAME', res.username);
        localStorage.setItem('SPREAD-CRM-NICKNAME', res.nickname);
        localStorage.setItem('SPREAD-CRM-AVATAR', res.faceUrl);
        localStorage.setItem('SPREAD-CRM-TOKEN', res.token);
        location.reload();
      }, (res) => {
        this.loading = false;
        this.$notify.error({
          title: 'Error',
          message: res.msg
        });
      });
    }

  }
}
</script>
